/* eslint no-console: off */
import React, {
  createContext,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {useSession} from './SessionContext';
import {pathStripSlashes} from '../utils';
import {
  BLINK_UID,
  setLocalToken,
  setLocalTokenRefresh,
  resetLocalTokens,
  getLocalToken,
  getLocalTokenRefresh,
  setImpersonate,
  resetImpersonate,
  getImpersonateKey,
} from './utils';

class ApiError extends Error {
  constructor(json, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.status = json.status;
    this.data = json.data;
  }
}

class ApiErrorInvalidToken extends Error {}

const request = async (method, path, data) => {
  const token = getLocalToken();

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  headers['X-BLINK-UID'] = BLINK_UID;

  const searchParams =
    method === 'get' && data ? new URLSearchParams(data) : null;

  const extra = {};
  if (data && method !== 'get') {
    extra.body = JSON.stringify(data);
  }

  const url = `/api/v1/${pathStripSlashes(path)}/`;
  const response = await fetch(
    searchParams ? `${url}?${searchParams.toString()}` : url,
    {
      method,
      headers,
      ...extra,
    },
  );

  if (![200, 201, 204, 400, 401].includes(response.status)) {
    throw new ApiError({
      status: response.status,
      data: response.statusText,
    });
  }

  // Parse the Content-Disposition header so we can download files from the API.
  const responseContentDisposition = response.headers.get(
    'Content-Disposition',
  );
  if (responseContentDisposition) {
    const dispositionFields = responseContentDisposition.split(';');
    const dispositionType = dispositionFields[0];
    if (dispositionType === 'attachment') {
      const dispositionFilename = dispositionFields[1]?.split('filename=')[1];
      const downloadBlob = await response.blob();
      const downloadFileUrl = URL.createObjectURL(downloadBlob);
      const downloadAnchor = document.createElement('a');
      downloadAnchor.href = downloadFileUrl;
      if (dispositionFilename) {
        downloadAnchor.download = dispositionFilename;
      }
      downloadAnchor.click();
    }

    // TODO: Maybe return early?
  }

  let json;
  try {
    json = await response.json();
  } catch (e) {
    json = {};
  }

  if ([200, 201, 204].includes(response.status)) {
    return json;
  }

  if (
    response.status === 401 &&
    path !== '/token-refresh' &&
    json.code === 'token_not_valid'
  ) {
    const refresh = getLocalTokenRefresh();

    // clean tokens in case the refresh goes wrong
    resetLocalTokens();
    if (refresh) {
      const refreshData = await request('post', '/token-refresh', {refresh});
      if (response.status === 401 && refreshData.access) {
        setLocalToken(refreshData.access);
        setLocalTokenRefresh(refreshData.refresh);

        // retry request after refresh
        return request(method, path, data);
      }
    }
  } else if (
    response.status === 401 &&
    path === '/token-refresh' &&
    json.code === 'token_not_valid'
  ) {
    throw new ApiErrorInvalidToken();
  }

  throw new ApiError({
    status: response.status,
    data: json,
  });
};

const ApiContext = createContext();

const ApiProvider = ({children}) => {
  const [, sessionActions] = useSession();

  const wrap = useCallback(
    (fn) =>
      async (...args) => {
        try {
          const res = await fn(...args);
          return res;
        } catch (e) {
          if (e instanceof ApiErrorInvalidToken) {
            sessionActions.finish();

            // @TODO popup a message letting the user know that the session expired
            return null;
          }

          throw e;
        }
      },
    [sessionActions],
  );

  const api = useMemo(
    () => ({
      logout: () => {
        resetLocalTokens();
        resetImpersonate();
      },
      login: async (values) => {
        const data = await request('post', '/token', values);
        setLocalToken(data.access);
        setLocalTokenRefresh(data.refresh);
      },
      impersonate: async (userId) => {
        const data = await request('post', `/impersonate/${userId}/`);
        setImpersonate();
        setLocalToken(data.access);
        setLocalTokenRefresh(data.refresh);
      },
      getSession: wrap(() => request('post', '/session')),
      get: wrap((path, data) => request('get', path, data)),
      post: wrap((path, data) => request('post', path, data)),
      put: wrap((path, data) => request('put', path, data)),
      delete: wrap((path) => request('delete', path)),
    }),
    [wrap],
  );

  const isImpersonated = getImpersonateKey();

  useEffect(() => {
    const listener = () => {
      api.logout();
      sessionActions.finish();
    };

    if (isImpersonated) {
      window.addEventListener('beforeunload', listener);
    }

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [api, sessionActions, isImpersonated]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('`useApi` must be used within a `ApiProvider`');
  }
  return context;
};

export {ApiContext, ApiProvider, useApi};
