import {useEffect, useMemo} from 'react';
import {useLayout} from '../contexts/LayoutContext';
import i18n from '../translations/i18n';

const useLayoutSidebarItems = () => {
  const SIDEBAR_TOP_ITEMS = [
    {
      icon: 'fa-solid fa-house',
      path: '/admin',
      label: i18n.t('pages:Sidebar.Home.label'),
    },
    {
      icon: 'fa-solid fa-cart-shopping',
      path: '/admin/products',
      label: i18n.t('pages:Sidebar.Products.label'),
      children: [
        {
          icon: 'fa-solid fa-circle',
          path: '/admin/products/add',
          label: i18n.t('pages:Sidebar.Products.AddProduct.label'),
        },
        {
          icon: 'fa-solid fa-circle',
          path: '/admin/products/import',
          label: i18n.t('pages:Sidebar.Products.ImportProducts.label'),
        },
        {
          icon: 'fa-solid fa-circle',
          path: '/admin/products/price-list',
          label: i18n.t('pages:Sidebar.Products.PriceLists.label'),
        },
        {
          icon: 'fa-solid fa-circle',
          path: '/admin/products/categories',
          label: i18n.t('pages:Sidebar.Products.Categories.label'),
        },
        {
          icon: 'fa-solid fa-circle',
          path: '/admin/products/variants',
          label: i18n.t('pages:Sidebar.Products.Variants.label'),
        },
      ],
    },
    {
      icon: 'fa-solid fa-bag-shopping',
      path: '/admin/purchase-orders',
      label: i18n.t('pages:Sidebar.PurchaseOrders.label'),
    },
    {
      icon: 'fa-solid fa-paper-plane',
      path: '/admin/blinks',
      label: i18n.t('pages:Sidebar.Blinks.label'),
    },
    {
      icon: 'fa-solid fa-user',
      path: '/admin/users',
      label: i18n.t('pages:Sidebar.Users.label'),
      children: [
        {
          icon: 'fa-solid fa-circle',
          path: '/admin/users/custom-fields',
          label: i18n.t('pages:Sidebar.Users.CustomFields.label'),
        },
      ],
    },
    {
      icon: 'fa-solid fa-dollar-sign',
      path: '/',
      label: i18n.t('pages:Sidebar.GoToStore.label'),
      openInNewWindow: true,
    },
  ];

  const SIDEBAR_BOTTOM_ITEMS = [
    {
      icon: 'fa-solid fa-gear',
      path: '/admin/settings',
      label: i18n.t('Sidebar.Settings.label'),
    },
    {
      icon: 'fa-solid fa-circle-question',
      href: 'https://api.whatsapp.com/send/?phone=5491155797437&text&type=phone_number&app_absent=0',
      label: i18n.t('Sidebar.Help.label'),
      target: '_blank',
    },
    {
      icon: 'fa-solid fa-right-from-bracket',
      path: '/logout',
      label: i18n.t('Sidebar.SignOut.label'),
    },
  ];

  return [SIDEBAR_TOP_ITEMS, SIDEBAR_BOTTOM_ITEMS];
};

const useLayoutTopBar = (type) => {
  const [, layoutActions] = useLayout();

  useEffect(() => {
    layoutActions.setTopBarType(type);
    return () => {
      layoutActions.setTopBarType(null);
    };
  }, [layoutActions, type]);
};

const useLayoutTitle = (title) => {
  const [, layoutActions] = useLayout();

  const memoized = useMemo(
    () => ({
      label: title.label,
      icon: title.icon,
    }),
    [title.label, title.icon],
  );

  useEffect(() => {
    layoutActions.setTitle(memoized);
    return () => {
      layoutActions.setTitle(null);
    };
  }, [layoutActions, memoized]);
};

const useLayoutButtons = () => {
  const [layoutState, layoutActions] = useLayout();
  return [
    layoutState.buttons,
    layoutActions.setButtons,
    layoutActions.clearButtons,
  ];
};

export {
  useLayoutSidebarItems,
  useLayoutTopBar,
  useLayoutTitle,
  useLayoutButtons,
};
