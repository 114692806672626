/* eslint-disable react/jsx-props-no-spreading */
import React, {useCallback, useMemo, useState} from 'react';
import {Col, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {Column, useTable} from 'react-table';
import {useDeviceWindow} from '../../contexts/DeviceWindowContext';
import {
  CombinationQuantitySetter,
  ShopProduct,
  ShopProductCombination,
  ShopProductPack,
  ShopProductVariant,
} from '../../types/ShopProduct';
import combinationMatchesFilters, {
  CombinationsFilter,
} from './combinationsFilter';
import {
  mobileColumn,
  packColumn,
  priceColumn,
  quantityColumn,
  skuColumn,
  variantColumn,
} from './CombinationsMatrixColumns';

import styles from './ShopProduct.module.scss';

interface PackFilterProps {
  packs: ShopProductPack[];
  filters?: CombinationsFilter;
  setFilters: React.Dispatch<React.SetStateAction<CombinationsFilter>>;
}
const PackFilter = ({packs, filters, setFilters}: PackFilterProps) => {
  const setterEnabled = !!filters;

  const handleSelectPack = useCallback(
    (pack: ShopProductPack) => {
      setFilters((prevFilters) => {
        if (prevFilters.pack === pack.name) {
          return {...prevFilters, pack: undefined};
        }

        return {
          ...prevFilters,
          pack: pack.name,
        };
      });
    },
    [setFilters],
  );

  return (
    <div className={styles.pills}>
      <div>Packs</div>
      <div>
        {packs.map((pack) => {
          const isToggled = filters?.pack === pack.name;

          return (
            <OverlayTrigger
              key={pack.name}
              placement="top"
              overlay={<Tooltip>{pack.quantity} unidades</Tooltip>}
            >
              <button
                type="button"
                className={isToggled ? styles.active : undefined}
                disabled={!setterEnabled}
                // TODO: no need to check setter enabled i think...
                onClick={() => setterEnabled && handleSelectPack(pack)}
              >
                {pack.name}
              </button>
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
};

interface VariantFilterProps {
  variant: ShopProductVariant;
  filters?: CombinationsFilter;
  setFilters: React.Dispatch<React.SetStateAction<CombinationsFilter>>;
}
const VariantFilter = ({variant, filters, setFilters}: VariantFilterProps) => {
  const setterEnabled = !!filters;

  const handleToggleOption = useCallback(
    (variantId: number, optionId: number) => {
      setFilters((prevFilters) => {
        if (
          prevFilters.variants.find(
            (el) => el.variant === variantId && el.option === optionId,
          )
        ) {
          // The option is already selected, un-select it.
          const newFilterVariants = prevFilters.variants.filter(
            (el) => el.variant !== variantId && el.option !== optionId,
          );

          return {
            ...prevFilters,
            variants: newFilterVariants,
          };
        }

        if (prevFilters.variants.find((el) => el.variant === variantId)) {
          const newFilterVariants = prevFilters.variants.map((el) => {
            if (el.variant === variantId) {
              return {
                variant: variantId,
                option: optionId,
              };
            }

            return el;
          });

          return {
            ...prevFilters,
            variants: newFilterVariants,
          };
        }

        return {
          ...prevFilters,
          variants: [
            ...prevFilters.variants,
            {variant: variantId, option: optionId},
          ],
        };
      });
    },
    [setFilters],
  );

  return (
    <div className={styles.pills}>
      <div>{variant.name}</div>
      <div>
        {variant.options.map((option) => {
          const isToggled = filters?.variants.find(
            (v) => v.option === option.id,
          );

          return (
            <button
              key={option.id}
              type="button"
              className={isToggled ? styles.active : undefined}
              disabled={!setterEnabled}
              // TODO: no need to check setter enabled, i think.
              onClick={() =>
                setterEnabled && handleToggleOption(variant.id, option.id)
              }
            >
              {option.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

interface CombinationsFiltersProps {
  product: ShopProduct;
  filters?: CombinationsFilter;
  setFilters: React.Dispatch<React.SetStateAction<CombinationsFilter>>;
}
const CombinationsFilters = ({
  product,
  filters,
  setFilters = (prevFilters) => prevFilters,
}: CombinationsFiltersProps) => (
  <Row className="gx-1">
    <Col>
      <PackFilter
        packs={product.packs}
        filters={filters}
        setFilters={setFilters}
      />
      {product.variants.map((variant) => (
        <VariantFilter
          key={variant.id}
          variant={variant}
          filters={filters}
          setFilters={setFilters}
        />
      ))}
    </Col>
  </Row>
);

interface CombinationsMatrixProps {
  product: ShopProduct;
  setCombinationQuantity?: CombinationQuantitySetter;
  currency: string;
  filters: CombinationsFilter;
}
const CombinationsMatrix = ({
  product,
  setCombinationQuantity,
  currency,
  filters,
}: CombinationsMatrixProps) => {
  const {isMobile} = useDeviceWindow();
  const columns = useMemo<Column<ShopProductCombination>[]>(() => {
    const cols = isMobile
      ? [mobileColumn(product, currency)]
      : [
          packColumn,
          ...product.variants.map(variantColumn),
          skuColumn(product),
        ];
    if (product.volume_discounts.length === 0 && !isMobile) {
      cols.push(priceColumn(product, currency));
    }
    cols.push(quantityColumn(product, setCombinationQuantity));
    return cols;
  }, [product, currency, setCombinationQuantity, isMobile]);

  const filteredCombinations = useMemo<ShopProductCombination[]>(
    () =>
      product.combinations.filter((combination) =>
        combinationMatchesFilters(filters, combination),
      ),
    [product, filters],
  );

  const {getTableProps, getTableBodyProps, prepareRow, headerGroups, rows} =
    useTable({
      columns,
      data: filteredCombinations,
    });

  const headerGroup = useMemo(() => headerGroups[0], [headerGroups]);

  return (
    <Row className="gx-1">
      <div className="table-responsive">
        <table
          className={`${styles.tableCombination} table`}
          {...getTableProps()}
        >
          <thead>
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
            {!filteredCombinations.length && (
              <tr>
                <td
                  className={styles.tableInfo}
                  colSpan={headerGroup.headers.length}
                >
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Row>
  );
};

interface CombinationsMatrixAndFiltersProps {
  product: ShopProduct;
  currency: string;
  setCombinationQuantity?: CombinationQuantitySetter;
}
const CombinationsMatrixAndFilters = ({
  product,
  currency,
  setCombinationQuantity,
}: CombinationsMatrixAndFiltersProps) => {
  const [filters, setFilters] = useState<CombinationsFilter>({
    pack: undefined,
    variants: [],
  });

  return (
    <React.Fragment>
      <CombinationsFilters
        product={product}
        filters={filters}
        setFilters={setFilters}
      />
      <CombinationsMatrix
        product={product}
        filters={filters}
        currency={currency}
        setCombinationQuantity={setCombinationQuantity}
      />
    </React.Fragment>
  );
};

export {CombinationsFilters, CombinationsMatrix, CombinationsMatrixAndFilters};
