const STORAGE = window.localStorage;
const BLINK_UID = document.BLINK_BLINK_UID;
const STORAGE_KEY_TOKEN = `bl:v2:${BLINK_UID}:token`;
const STORAGE_KEY_TOKEN_REFRESH = `bl:v2:${BLINK_UID}:token-refresh`;

const ADMIN_KEY_TOKEN = `bl:v2:${BLINK_UID}:admin:token`;
const ADMIN_KEY_TOKEN_REFRESH = `bl:v2:${BLINK_UID}:admin:token-refresh`;
const IMPERSONATE_KEY = `bl:v2:${BLINK_UID}:impersonate`;

function setLocalToken(token) {
  const isImpersonated = getImpersonateKey();
  const key = isImpersonated ? ADMIN_KEY_TOKEN : STORAGE_KEY_TOKEN;

  if (!token) {
    STORAGE.removeItem(key);
  } else {
    STORAGE.setItem(key, token);
  }
}

function setLocalTokenRefresh(tokenRefresh) {
  const isImpersonated = getImpersonateKey();
  const key = isImpersonated
    ? ADMIN_KEY_TOKEN_REFRESH
    : STORAGE_KEY_TOKEN_REFRESH;

  if (!tokenRefresh) {
    STORAGE.removeItem(key);
  } else {
    STORAGE.setItem(key, tokenRefresh);
  }
}

function resetLocalTokens() {
  setLocalToken(null);
  setLocalTokenRefresh(null);
}

function getLocalToken() {
  const isImpersonated = getImpersonateKey();
  const key = isImpersonated ? ADMIN_KEY_TOKEN : STORAGE_KEY_TOKEN;

  return STORAGE.getItem(key);
}

function getLocalTokenRefresh() {
  const isImpersonated = getImpersonateKey();
  const key = isImpersonated
    ? ADMIN_KEY_TOKEN_REFRESH
    : STORAGE_KEY_TOKEN_REFRESH;

  return STORAGE.getItem(key);
}

function setImpersonate() {
  STORAGE.setItem(IMPERSONATE_KEY, true);
}

function resetImpersonate() {
  STORAGE.removeItem(IMPERSONATE_KEY);
}

function getImpersonateKey() {
  return STORAGE.getItem(IMPERSONATE_KEY);
}

export {
  BLINK_UID,
  setLocalToken,
  setLocalTokenRefresh,
  resetLocalTokens,
  getLocalToken,
  getLocalTokenRefresh,
  setImpersonate,
  resetImpersonate,
  getImpersonateKey,
};
