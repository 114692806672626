import React, {useContext, createContext, useMemo, useReducer} from 'react';
import {Settings, Blink, Cart, Categories} from '../types/Ecommerce';
import {ActionMapper} from '../types/utils';

interface EcommerceContextActions {
  setStore: (payload: EcommerceContextState) => void;
  setCart: (payload: Cart) => void;
  resetStore: () => void;
}

interface PriceList {
  name: string;
  label: string | null;
  id: number;
}

const EcommerceContext = createContext<
  [EcommerceContextState, EcommerceContextActions] | undefined
>(undefined);

type EcommerceContextState = {
  title: string;
  uid: string | null;
  settings: Settings;
  blink: Partial<Blink>;
  cart: Partial<Cart>;
  categories: Categories | null;
  priceList: PriceList | null;
};

const INITIAL_STATE: EcommerceContextState = {
  title: '',
  uid: null,
  settings: {},
  blink: {},
  cart: {},
  categories: null,
  priceList: null,
};

export type EcommerceContextActionsMap = {
  SET_STORE: EcommerceContextState;
  SET_CART: Cart;
};

type EcommerceContextAction = ActionMapper<EcommerceContextActionsMap>;

const reducer = (
  state: EcommerceContextState,
  action: EcommerceContextAction,
): EcommerceContextState => {
  switch (action.type) {
    case 'SET_STORE':
      return {
        ...action.payload,
      };
    case 'SET_CART': {
      return {
        ...state,
        cart: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const EcommerceProvider = ({children}: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const actions: EcommerceContextActions = useMemo(
    () => ({
      setStore: (payload: EcommerceContextState) => {
        dispatch({
          type: 'SET_STORE',
          payload,
        });
      },
      setCart: (payload: Cart) => {
        dispatch({
          type: 'SET_CART',
          payload,
        });
      },
      resetStore: () => {
        dispatch({
          type: 'SET_STORE',
          payload: INITIAL_STATE,
        });
      },
    }),
    [],
  );

  const value: [EcommerceContextState, EcommerceContextActions] = useMemo(
    () => [state, actions],
    [state, actions],
  );

  return (
    <EcommerceContext.Provider value={value}>
      {children}
    </EcommerceContext.Provider>
  );
};

const useEcommerce = () => {
  const context = useContext(EcommerceContext);
  if (context === undefined) {
    throw new Error('`useEcommerce` must be used within a `EcommerceProvider`');
  }
  return context;
};

export {EcommerceContext, EcommerceProvider, useEcommerce};
