import React, {useCallback, useMemo} from 'react';
import {Column} from 'react-table';
import {useShopProductsContainer} from './Container';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {ShopProduct} from '../../types/ShopProduct';
import {useApi} from '../../contexts/ApiContext';
import Table from './Table';
import {
  buttonsColumn,
  defaultCodeColumn,
  expandRowToggleColumn,
  highlightProductColumn,
  imageWithTitleAndPriceRangeColumn,
  imageWithTitleColumn,
  priceRangeColumn,
  quantityColumn,
  quantityColumnMobile,
} from './TableColumns';
import {RegularExpandedRowContent} from './ExpandedRow';

import {useDeviceWindow} from '../../contexts/DeviceWindowContext';

interface ShopTableProps {
  toggleFavorite: (product: ShopProduct) => Promise<void>;
}
const ShopTable = ({toggleFavorite}: ShopTableProps) => {
  const api = useApi();
  const [{uid, settings}, actions] = useEcommerce();
  const {mutateProduct} = useShopProductsContainer();
  const {isMobile} = useDeviceWindow();

  const setCombinationQuantity = useCallback(
    async (productId: number, combinationId: string, newQuantity: number) => {
      const result = await api.post(`/blink/${uid}/${productId}/`, {
        combination: combinationId,
        quantity: newQuantity,
      });

      mutateProduct(productId, (prevProduct) => ({
        ...prevProduct,
        ...result.product,
      }));

      actions.setCart(result.cart);
    },
    [api, uid, mutateProduct, actions],
  );

  const currency = settings.COMPANY_CURRENCY;
  const taxesEnabled = settings.COMPANY_PRICE_WITH_TAXES;

  const columns = useMemo<Column<ShopProduct>[]>(
    () =>
      isMobile
        ? [
            imageWithTitleAndPriceRangeColumn(
              currency,
              taxesEnabled,
              toggleFavorite,
            ),
            quantityColumnMobile(setCombinationQuantity),
            expandRowToggleColumn(),
          ]
        : [
            imageWithTitleColumn,
            priceRangeColumn(currency, taxesEnabled),
            highlightProductColumn,
            defaultCodeColumn,
            quantityColumn(setCombinationQuantity),
            buttonsColumn(toggleFavorite),
          ],
    [isMobile, currency, taxesEnabled, setCombinationQuantity, toggleFavorite],
  );

  return (
    <Table
      currency={currency}
      setCombinationQuantity={setCombinationQuantity}
      columns={columns}
      ExpandedRowContentComponent={RegularExpandedRowContent}
    />
  );
};

export default ShopTable;
