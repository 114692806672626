/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {Formik} from 'formik';
import {Button, Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import SelectField from '../FormFields/SelectField';
import TextField from '../FormFields/TextField';
import TypeField from '../FormFields/TypeField';
import NumberField from '../FormFields/NumberField';
import optionsFormatter from './utils';
import {useSession} from '../../contexts/SessionContext';

const USER_TYPE = [
  [false, 'Cliente'],
  [true, 'Administrador'],
];

const UrlView = ({link}) => (
  <div>
    <Button href={link || null} disabled={!link}>
      Ver <Icon icon="fa-solid fa-download" />
    </Button>
  </div>
);

const UrlField = ({children, ...props}) => <TypeField type="url" {...props} />;

const FIELD_EDIT_MAP = {
  text: TextField,
  select: SelectField,
  url: UrlField,
};

const FIELD_VIEW_MAP = {
  text: TextField,
  select: SelectField,
  url: UrlView,
};

const UserForm = React.forwardRef(
  ({initialValues, handleSubmit, validationSchema, customFields, priceLists, children}, ref) => {
    const {t} = useTranslation('components');
    const [, , selectors] = useSession();

    const priceListsOptions = [
      ['', t('Forms.UserForm.labels.default_price_list')],
      ...(priceLists ? priceLists.map((pl) => [pl.id, pl.name]) : []),
    ];

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        handleChange
        handleBlur
      >
        {(form) => (
          <Form
            ref={() => {
              // eslint-disable-next-line no-param-reassign
              ref.current = form;
            }}
            noValidate
            onSubmit={form.handleSubmit}
          >
            {form.initialValues.is_staff !== undefined ? (
              <Form.Group className="mb-2 position-relative">
                <Form.Label>{t('Forms.UserForm.labels.type')}</Form.Label>
                <SelectField name="is_staff" options={USER_TYPE} />
              </Form.Group>
            ) : null}
            <Form.Group className="mb-2 position-relative">
              <Form.Label>{t('Forms.UserForm.labels.first_name')}</Form.Label>
              <TextField name="first_name" />
            </Form.Group>
            <Form.Group className="mb-2 position-relative">
              <Form.Label>{t('Forms.UserForm.labels.last_name')}</Form.Label>
              <TextField name="last_name" />
            </Form.Group>
            <Form.Group className="mb-2 position-relative">
              <Form.Label>{t('Forms.UserForm.labels.username')}</Form.Label>
              <TextField name="username" disabled={form.initialValues.username} />
            </Form.Group>
            <Form.Group className="mb-2 position-relative">
              <Form.Label>{t('Forms.UserForm.labels.email')}</Form.Label>
              <TypeField name="email" type="email" />
            </Form.Group>
            <Form.Group className="mb-2 position-relative">
              <Form.Label>{t('Forms.UserForm.labels.user_phone')}</Form.Label>
              <NumberField name="user_phone" />
            </Form.Group>
            <Form.Group className="mb-2 position-relative">
              <Form.Label>{t('Forms.UserForm.labels.user_company_name')}</Form.Label>
              <TextField name="user_company_name" />
            </Form.Group>
            <Form.Group className="mb-2 position-relative">
              <Form.Label>{t('Forms.UserForm.labels.user_company_tax_id')}</Form.Label>
              <NumberField name="user_company_tax_id" />
            </Form.Group>
            <Form.Group className="mb-2 position-relative">
              <Form.Label>{t('Forms.UserForm.labels.user_shipping_address')}</Form.Label>
              <TextField name="user_shipping_address" />
            </Form.Group>
            {selectors.isAdminSession ? (
              <React.Fragment>
                <Form.Group className="mb-2 position-relative">
                  <Form.Label>{t('Forms.UserForm.labels.client_ref')}</Form.Label>
                  <TextField name="client_ref" />
                </Form.Group>
                <Form.Group className="mb-2 position-relative">
                  <Form.Label>{t('Forms.UserForm.labels.price_list')}</Form.Label>
                  <SelectField name="price_list" options={priceListsOptions} />
                </Form.Group>
              </React.Fragment>
            ) : null}

            {customFields
              .filter((customField) => selectors.isAdminSession || customField.visible)
              .map((customField) => {
                const isEditable = selectors.isAdminSession || customField.edit_permission;

                const FieldComponent = isEditable
                  ? FIELD_EDIT_MAP[customField.type]
                  : FIELD_VIEW_MAP[customField.type];

                const fieldProps = {
                  name: `custom_fields.${customField.name}`,
                  disabled: !isEditable,
                  options: optionsFormatter(customField.options),
                };

                if (customField.type === 'url') {
                  fieldProps.link = initialValues.custom_fields[customField.name];
                }

                return (
                  <Form.Group className="mb-2 position-relative" key={customField.name}>
                    <Form.Label>{customField.label}</Form.Label>
                    <FieldComponent {...fieldProps} />
                  </Form.Group>
                );
              })}
            {children}
          </Form>
        )}
      </Formik>
    );
  },
);

export default UserForm;
