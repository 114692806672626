import React, {useReducer, useMemo} from 'react';
import PropTypes from 'prop-types';

const initialState = {
  loaded: false,
  data: {},
};

const SessionContext = React.createContext();

const types = {
  SESSION_INIT: 'SESSION_INIT',
  SESSION_FINISH: 'SESSION_FINISH',
  SESSION_SET_DATA: 'SESSION_SET_DATA',
};

const reducer = (state, action) => {
  switch (action.type) {
    case types.SESSION_INIT: {
      return {
        ...state,
        loaded: true,
      };
    }
    case types.SESSION_FINISH: {
      return {
        ...initialState,
      };
    }
    case types.SESSION_SET_DATA: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const SessionProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const selectors = useMemo(
    () => ({
      isFullAnonymousSession: state.loaded && !state.data.user,
      isAnonymousCustomerSession:
        state.loaded &&
        !!state.data.user &&
        state.data.user.type === 'anonymous_customer',
      isRegisteredCustomerSession:
        state.loaded &&
        !!state.data.user &&
        state.data.user.type === 'customer',
      isAdminSession:
        state.loaded && !!state.data.user && state.data.user.type === 'admin',
    }),
    [state],
  );

  const actions = useMemo(
    () => ({
      init: () =>
        dispatch({
          type: types.SESSION_INIT,
        }),
      finish: () =>
        dispatch({
          type: types.SESSION_FINISH,
        }),
      setSession: (data) => {
        dispatch({
          type: types.SESSION_SET_DATA,
          payload: {
            data: data || {},
          },
        });
      },
    }),
    [dispatch],
  );

  const value = useMemo(
    () => [state, actions, selectors],
    [state, actions, selectors],
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useSession = () => {
  const context = React.useContext(SessionContext);

  if (context === undefined) {
    throw new Error('`useSession` must be used within a `SessionContext`.');
  }
  return context;
};

export {SessionContext, SessionProvider, useSession};
