import React from 'react';
import {useTranslation} from 'react-i18next';
import {ShopProduct} from '../../types/ShopProduct';
import {formatMoney} from '../../utils';

import styles from './StoreProduct.module.scss';

interface ProductPriceProps {
  product: ShopProduct;
  currency: string;
  taxesEnabled: boolean;
}

const ProductPrice = ({product, currency, taxesEnabled}: ProductPriceProps) => {
  const {t} = useTranslation('pages');

  const renderStrikeThroughPrice = () => {
    if (
      product.price_range.offer_price.min ===
      product.price_range.offer_price.max
    ) {
      return formatMoney(product.default_price, currency);
    }

    return `${t('StoreProductPage.ProductPrice.from')} ${formatMoney(
      product.price_range.default_price.min,
      currency,
    )}`;
  };

  const renderPrice = () => {
    if (
      product.price_range.default_price.min ===
        product.price_range.default_price.max &&
      product.offer_price
    ) {
      return formatMoney(product.offer_price, currency);
    }

    if (
      product.price_range.default_price.min !==
        product.price_range.default_price.max &&
      !product.offer_price
    ) {
      return `${t('StoreProductPage.ProductPrice.from')} ${formatMoney(
        product.price_range.default_price.min,
        currency,
      )}`;
    }

    if (!product.offer_price) {
      return formatMoney(product.default_price, currency);
    }

    return `${t('StoreProductPage.ProductPrice.from')} ${formatMoney(
      product.price_range.offer_price.min,
      currency,
    )}`;
  };

  return (
    <div className={styles.productPrice}>
      {product.offer_price ? <h5>{renderStrikeThroughPrice()}</h5> : null}
      <h3>
        {renderPrice()}
        {taxesEnabled ? <span>+IVA</span> : null}
      </h3>
      {product.price_for_list ? (
        <p className={styles.priceList}>
          {t('StoreProductPage.ProductPrice.priceList', {
            discount: product.discount,
            price: formatMoney(product.price_for_list, currency),
          })}
        </p>
      ) : null}
    </div>
  );
};

export default ProductPrice;
