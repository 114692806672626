import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {useShopProductsContainer} from './Container';
import placeholder from '../../assets/placeholder-image.png';
import {ShopProduct, ShopProductPriceRange} from '../../types/ShopProduct';
import {formatMoney} from '../../utils';
import {useEcommerce} from '../../contexts/EcommerceContext';

import styles from './ShopProduct.module.scss';
import NewProductSVG from '../../assets/NewProductSVG';

interface ProductPriceProps {
  priceRange: ShopProductPriceRange;
  defaultPrice: string;
  offerPrice: string;
}
const ProductPrice = ({
  priceRange,
  defaultPrice,
  offerPrice,
}: ProductPriceProps) => {
  const [{settings}] = useEcommerce();
  const currency = settings.COMPANY_CURRENCY;
  const taxesEnabled = settings.COMPANY_PRICE_WITH_TAXES;

  const renderStrikeThroughPrice = () => {
    if (priceRange.offer_price.min === priceRange.offer_price.max) {
      return formatMoney(defaultPrice, currency);
    }

    return `Desde ${formatMoney(priceRange.default_price.min, currency)}`;
  };

  const renderPrice = () => {
    if (
      priceRange.default_price.min === priceRange.default_price.max &&
      offerPrice
    ) {
      return formatMoney(offerPrice, currency);
    }

    if (
      priceRange.default_price.min !== priceRange.default_price.max &&
      !offerPrice
    ) {
      return `Desde ${formatMoney(priceRange.default_price.min, currency)}`;
    }

    if (!offerPrice) {
      return formatMoney(defaultPrice, currency);
    }

    return `Desde ${formatMoney(priceRange.offer_price.min, currency)}`;
  };

  return (
    <div>
      {offerPrice ? <p>{renderStrikeThroughPrice()}</p> : null}
      <strong>
        {renderPrice()}
        {taxesEnabled ? <span>+IVA</span> : null}
      </strong>
    </div>
  );
};

interface ShopGridCellProps {
  product: ShopProduct;
  toggleFavorite: (product: ShopProduct) => Promise<void>;
}
const ShopGridCell = ({product, toggleFavorite}: ShopGridCellProps) => {
  const navigate = useNavigate();

  return (
    <Col xs={6} md={6} lg={3}>
      <Card
        className={styles.grid}
        onClick={() => navigate(`/product/${product.id}`)}
      >
        <Card.Header className={styles.gridHeader}>
          <div className={styles.headerButtons}>
            <button
              type="button"
              className={`${styles.favoriteButton} ${
                product.favorite ? styles.active : undefined
              }`}
              onClick={(event) => {
                event.stopPropagation();
                toggleFavorite(product);
              }}
            >
              <Icon icon="heart" />
            </button>
            {product.tags?.some((tag) => tag.name === 'new') && (
              <NewProductSVG className={styles.newIconTable} />
            )}
          </div>
          <Card.Img
            variant="top"
            className={styles.productImage}
            src={product.images[0] || placeholder}
          />
        </Card.Header>
        <Card.Body className={styles.gridBody}>
          <React.Fragment>
            <p className={`${styles.gridProductTitle} fw-bold`}>
              {product.name}
            </p>
            <p className={`${styles.highlightCell} text-muted fw-bold`}>
              {product.custom_fields['2bk_show'] ? (
                product.custom_fields['2bk_show']
              ) : (
                <React.Fragment>&nbsp;</React.Fragment>
              )}
            </p>
          </React.Fragment>
          <Card.Text>{product.ref}</Card.Text>
          <ProductPrice
            priceRange={product.price_range}
            defaultPrice={product.default_price}
            offerPrice={product.offer_price}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};

interface ShopGridProps {
  toggleFavorite: (product: ShopProduct) => Promise<void>;
}
const ShopGrid = ({toggleFavorite}: ShopGridProps) => {
  const {products} = useShopProductsContainer();

  return (
    <Row className="gx-2 gy-1">
      {products.map((product) => (
        <ShopGridCell
          key={product.id}
          product={product}
          toggleFavorite={toggleFavorite}
        />
      ))}
    </Row>
  );
};

export default ShopGrid;
