import React, {useEffect, useMemo} from 'react';
import {toast} from 'react-toastify';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Route, Routes, useNavigate} from 'react-router';
import {useApi} from '../../contexts/ApiContext';
import {useLayoutTitle} from '../../hooks/layout';
import UsersFormPage from './UsersFormPage';
import DataTable from '../../components/DataTable';
import {useLayout} from '../../contexts/LayoutContext';
import {FILTERABLE_TYPES} from '../../components/DataTable/utils';
import useSearchParamsFilters from '../../hooks/useSearchParamsFilters';
import UsersCustomFieldsPage from '../UsersCustomFieldsPage';

import styles from './UsersPage.module.scss';

const orderChoices = [
  {
    value: 'username',
    label: 'A → Z',
  },
  {
    value: '-username',
    label: 'Z → A',
  },
  {
    value: 'date_joined',
    label: 'Más antiguos',
  },
  {
    value: '-date_joined',
    label: 'Más nuevos',
  },
];

const UsersPageIndex = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [, actions] = useLayout();

  useEffect(() => {
    actions.setTopBar(<CallToActionButtons />);

    return () => {
      actions.setTopBar(null);
    };
  }, [actions]);

  useLayoutTitle({
    label: 'Usuarios',
    icon: 'fa-solid fa-user',
  });

  const columns = [
    {
      Header: 'Usuario',
      accessor: 'username',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Compañía',
      accessor: 'user_company_name',
    },
    {
      Header: 'Tipo',
      Cell: ({cell}) => (cell.value === 'admin' ? 'Administrador' : 'Cliente'),
      accessor: 'type',
    },
  ];

  const buttons = useMemo(
    () => [
      {
        icon: 'fa-solid fa-pencil',
        onClick: (cell) => navigate(`edit/${cell.row.original.id}/`),
        key: 'edit',
      },
      {
        icon: 'fa-solid fa-trash',
        onClick: (cell) => {
          api.put(`/user/${cell.row.original.id}`, {
            username: cell.row.original.username,
            is_active: false,
          });

          toast.success(`¡Usuario ${cell.row.original.username} eliminado!`, {
            hideProgressBar: true,
          });
        },
        key: 'delete',
        refresh: true,
      },
      {
        icon: 'fa-solid fa-store',
        onClick: (cell) => {
          api.impersonate(cell.row.original.id);
          window.open('/', '_blank');
        },
        key: 'impersonate',
      },
    ],
    [navigate, api],
  );

  const filterables = [
    {
      field: 'type',
      label: 'Tipo usuario',
      type: FILTERABLE_TYPES.CHOICES,
      choices: [
        {value: 'admin', label: 'Administradores'},
        {value: 'customer', label: 'Cliente'},
      ],
    },
  ];

  const {currentFilters, hasFilters} = useSearchParamsFilters(filterables);

  const initialFilters = useMemo(() => {
    const defaultFilters = {};
    return hasFilters ? currentFilters : defaultFilters;
  }, [currentFilters, hasFilters]);

  return (
    <DataTable
      columns={columns}
      buttons={buttons}
      orderChoices={orderChoices}
      filterables={filterables}
      initialFilters={initialFilters}
      source={{type: 'remote', url: '/user'}}
      allowOrder
      allowSearch
      searchPlaceholder="Busca usuarios..."
    />
  );
};

const CallToActionButtons = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.ctaButtons}>
      <Button variant="secondary" onClick={() => navigate('/admin/users/add')}>
        <Icon icon="fa-solid fa-user" /> + Agregar usuario
      </Button>
    </div>
  );
};

const UsersPage = () => (
  <Routes>
    <Route path="/add" element={<UsersFormPage />} />
    <Route path="/edit/:id" element={<UsersFormPage />} />
    <Route path="/custom-fields" element={<UsersCustomFieldsPage />} />
    <Route index element={<UsersPageIndex />} />
  </Routes>
);

export default UsersPage;
