import React, {useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Button, Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {useApi} from '../../contexts/ApiContext';
import {useSession} from '../../contexts/SessionContext';
import UserForm from '../../components/Forms/UserForm';

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  username: Yup.string().required(),
  email: Yup.string().email().required(),
  user_phone: Yup.number(),
  user_company_name: Yup.string(),
  user_company_tax_id: Yup.number(),
  user_shipping_address: Yup.string(),
  custom_fields: Yup.object(),
});

const StoreUserInformationPage = () => {
  const api = useApi();
  const [customer, setCustomer] = useState({
    username: '',
    email: '',
    full_name: '',
    first_name: '',
    last_name: '',
    user_phone: '',
    user_company_name: '',
    user_company_tax_id: '',
    user_shipping_address: '',
    custom_fields: {},
  });
  const [sessionState, sessionActions] = useSession();
  const [customFields, setCustomFields] = useState([]);
  const formRef = useRef();
  const {t} = useTranslation('pages');

  useEffect(() => {
    const fetchCustomer = async () => {
      const response = await api.get('/customer/');

      setCustomer(response);
    };

    const fetchCustomFields = async () => {
      const response = await api.get('/user/custom-field');

      setCustomFields(response.results);
    };

    fetchCustomer();
    fetchCustomFields();
  }, [api]);

  const handleSubmit = async (values) => {
    const customFieldsInitialValues = customFields.reduce((accum, current) => {
      accum[current.name] = '';

      return accum;
    }, {});

    try {
      const response = await api.put('/customer/', {
        ...values,
        custom_fields: {...customFieldsInitialValues, ...values.custom_fields},
      });

      setCustomer(response);
      sessionActions.setSession({
        user: {
          type: sessionState.data.user.type,
          username: response.username,
          email: response.email,
          info: {
            fullname: response.full_name,
            first_name: response.first_name,
            last_name: response.last_name,
            phone: response.user_phone,
            company_name: response.user_company_name,
            company_tax_id: response.user_company_tax_id,
            shipping_address: response.user_shipping_address,
          },
        },
      });
      toast.success('Tus datos han sido actualizados!', {
        hideProgressBar: true,
      });
    } catch (e) {
      toast.error(e, {hideProgressBar: true});
    }
  };

  validationSchema.withMutation((schema) => {
    const customFieldsValidation = customFields.reduce((accum, current) => {
      if (current.type === 'url') {
        accum[current.name] = Yup.string().url();
      } else {
        accum[current.name] = Yup.string();
      }
      return accum;
    }, {});

    schema.fields.custom_fields.withMutation((fieldSchema) =>
      fieldSchema.shape(customFieldsValidation),
    );
  });

  return (
    <Card className="mt-2 mx-2">
      <Card.Body>
        <h1>
          <Icon icon="fas fa-user" /> {t('StoreCustomerPage.title')}
        </h1>
        <UserForm
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          initialValues={customer}
          customFields={customFields}
          ref={formRef}
        >
          <Button
            type="button"
            size="lg"
            onClick={() => formRef.current.submitForm()}
            className="d-block ms-auto"
          >
            {t('StoreCustomerPage.button')}
          </Button>
        </UserForm>
      </Card.Body>
    </Card>
  );
};

export default StoreUserInformationPage;
