import React from 'react';
import {Row, Col} from 'react-bootstrap';
import CallToActionBlinkCreate from '../../components/CallToAction/CallToActionBlinkCreate';
import CallToActionProductsAdd from '../../components/CallToAction/CallToActionProductsAdd';
import CallToActionProductsImport from '../../components/CallToAction/CallToActionProductsImport';
import {useUtils} from '../../contexts/UtilsContext';

const DashboardPage = () => {
  const [utilsState] = useUtils();

  return (
    <Row className="mt-4">
      <Col lg={6}>
        <CallToActionBlinkCreate />
      </Col>
      <Col lg={3}>
        <CallToActionProductsAdd />
      </Col>
      <Col lg={3}>
        <CallToActionProductsImport />
      </Col>
      <Col lg={12} className="mt-4">
        {utilsState.settings.COMPANY_THEME_LOGO ? (
          <img
            src={utilsState.settings.COMPANY_THEME_LOGO}
            alt={utilsState.settings.COMPANY_NAME}
            width={150}
            className="float-end"
          />
        ) : (
          <div style={{width: 150, height: 150}} className="float-end" />
        )}
        <h3 className="text-primary fw-bold">
          {utilsState.settings.COMPANY_NAME
            ? `Hola ${utilsState.settings.COMPANY_NAME},`
            : 'Bienvenido a 2Blink,'}
        </h3>
        <p>
          Gracias por utilizar 2blink, la plataforma de eCommerce para Fábricas,
          Importadoras, Mayoristas y Distribuidoras.
        </p>
        <p>
          2blink es la primera plataforma para venta mayorista que combina la
          atención personalizada que los clientes necesitan con los beneficios
          de autoservicio propios del comercio electrónico. Un eCommerce
          construido a la medida de las necesidades B2B.
        </p>
        <p className="fw-bold">
          Por dudas sobre el producto o si necesitas soporte, no dudes en
          contactarnos a support@2blink.app o directamente por Whatsapp al{' '}
          <a
            href="https://wa.me/5491155797437"
            target="_blank"
            rel="noreferrer"
          >
            +5491155797437
          </a>
        </p>
      </Col>
    </Row>
  );
};

export default DashboardPage;
