/* eslint no-console: off */
import React, {createContext, useContext, useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {getLocalToken} from './utils';

const WebSocketContext = createContext();

const WebSocketProvider = ({children}) => {
  const [websocket, setWebsocket] = useState(null);

  // readyState
  // 0  CONNECTING  Socket has been created. The connection is not yet open.
  // 1  OPEN  The connection is open and ready to communicate.
  // 2  CLOSING  The connection is in the process of closing.
  // 3  CLOSED  The connection is closed or couldn't be opened.

  useEffect(() => {
    if (websocket !== null) {
      return;
    }

    let timer;
    const connect = async () => {
      console.log('[WS] connect');

      const ws = new WebSocket(
        `${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${
          window.location.host
        }/ws`,
      );
      setWebsocket(ws);

      const token = getLocalToken();

      ws.addEventListener('open', () => {
        console.log('[WS] connected');
        ws.send(JSON.stringify({token}));
      });

      ws.addEventListener('message', (event) => {
        console.log('[WS] message', event.data);
        const notification = JSON.parse(event.data)?.notification;

        if (!notification) {
          return;
        }
        const handler = {
          error: toast.error,
          success: toast.success,
        }[notification.type];

        if (handler) {
          handler(notification.message, {hideProgressBar: true});
        }
      });

      ws.addEventListener('error', () => ws.close());

      ws.addEventListener('close', () => {
        console.log('[WS] close');

        timer = setTimeout(() => {
          console.log('[WS] reconnect');
          if (ws.readyState === WebSocket.CLOSED) {
            setWebsocket(null);
          }
        }, 1000 * 5);
      });
    };

    connect();

    /* eslint consistent-return: off */
    return () => clearTimeout(timer);
  }, [websocket]);

  return (
    <WebSocketContext.Provider value={websocket}>
      {children}
    </WebSocketContext.Provider>
  );
};

WebSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('`useWebSocket` must be used within a `WebSocketProvider`');
  }
  return context;
};

export {WebSocketContext, WebSocketProvider, useWebSocket};
