import React, {useMemo, useState} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useEcommerce} from '../../contexts/EcommerceContext';
import {formatMoney} from '../../utils';

const BuyButton = () => {
  const [state] = useEcommerce();
  const [toggleTooltip, setToggleTooltip] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation('components');
  const allowCheckout = useMemo(
    () =>
      Number(state.cart.total) >=
        Number(state.settings.COMPANY_MINIMUM_PURCHASE) &&
      Number(state.cart.total) > 0,
    [state.settings.COMPANY_MINIMUM_PURCHASE, state.cart.total],
  );

  return (
    <OverlayTrigger
      placement="left"
      show={toggleTooltip}
      onToggle={() => {
        if (allowCheckout) {
          setToggleTooltip(false);
        } else {
          setToggleTooltip((prevState) => !prevState);
        }
      }}
      overlay={
        <Tooltip>
          {t('BuyButton.minimumPurchaseInfo', {
            amount: formatMoney(
              state.settings.COMPANY_MINIMUM_PURCHASE,
              state.settings.COMPANY_CURRENCY,
            ),
          })}
        </Tooltip>
      }
    >
      <div className="mt-2 ms-auto d-block" style={{width: 'fit-content'}}>
        <div className="d-flex">
          <Button
            className="me-1"
            onClick={() => navigate(-1)}
            variant="secondary"
            size="lg"
          >
            {t('BuyButton.goBack')}
          </Button>
          <Button
            onClick={() => navigate(`/checkout`)}
            variant="primary"
            size="lg"
            className="d-block"
            disabled={!allowCheckout}
          >
            {t('BuyButton.buy')}
          </Button>
        </div>
      </div>
    </OverlayTrigger>
  );
};

export default BuyButton;
